<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {paginationHelper} from "@/helpers/pagination-helper";
import {dateUtil} from "@/helpers/date-util";
import {routerHelper} from "@/helpers/router-helper";
import axios from "axios";
import {clipboardHelper} from "@/helpers/clipboard-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import Multiselect from "vue-multiselect";
import {required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {jsonUtil} from "@/helpers/json-util";
import DeliveryAddressTable from "../../../components/core/order-details/delivery-address-table.vue";
import {orderHelper} from "@/helpers/order-helper";
import {priceHelper} from "../../../helpers/price-helper";
import OrderLogsTable from "../../../components/core/order-details/order-logs-table.vue";
import OrderFeesLogsTable from "../../../components/core/order-details/order-fees-logs-table.vue";
import {toastHelper} from "../../../helpers/toast-helper";
import invoiceModal from "../../../components/core/order-details/invoice-modal.vue";
import allCountries from "../../../helpers/all-countries";
import createInvoiceCorrectionModal from "../../../components/core/order-details/create-invoice-correction-modal.vue";

/**
 * Core Order Details Component
 */
export default {

  components: {
    OrderFeesLogsTable,
    OrderLogsTable,
    DeliveryAddressTable,
    Multiselect,
    Layout,
    PageHeader,
    invoiceModal,
    createInvoiceCorrectionModal
  },

  computed: {
    priceHelper() {
      return priceHelper
    },

    orderHelper() {
      return orderHelper
    },

    jsonUtil() {
      return jsonUtil
    },

    clipboardHelper() {
      return clipboardHelper
    },

    dateUtil() {
      return dateUtil
    },

    paginationHelper() {
      return paginationHelper
    },

    linkSledzenia() {
      return process.env.VUE_APP_LINKSLEDZENIA_URL
    }
  },

  validations: {
    form: {
      comment: {
        description: {required}
      },

      massChangeProductStatus: {
        status: {required}
      },

      addProduct: {
        sku: {required},
        productVariationId: {required},
        amount: {required}
      },

      massWarehouseInfo: {

        warehouseInvoiceId: {required},
        warehouseOrderId: {required},
        warehousePrice: {required}

      },

      manualCorrection: {

        serviceId: {required},
        previewUrl: {required},
        price: {required},
        hash: {required},
      }

    },

    invoiceAddress: {
      city: {required},
      street: {required},
      postCode: {required},
      country: {required},
      firstName: {required},
      lastName: {required}
    },

    modals: {
      addTracking: {
        form: {
          trackingNumber: {required},
          trackingUrl: {required}
        }
      }
    }
  },

  data() {
    return {
      depotSectionStocks: [],
      productVariationsStockReservationAmount: new Map(),
      alternativeProducts: new Map(),
      customerId: "",
      loading: false,

      shopOrder: null,
      inpostParcelLockerAddress: null,
      shop: null,
      market: null,
      customer: null,
      editingShopOrder: null,

      carriers: null,
      carriersIds: null,
      operator: {},

      invoiceVats: [],
      invoiceCorrections: [],

      customerAddress: null,
      invoiceAddress: null,

      commentAuthors: null,
      loadingVariations: false,

      orderPayment: null,

      productsTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        items: []
      },

      commentsForLogisticsTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        items: []
      },

      commentsTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        items: []
      },

      productVariationsTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        elements: []
      },

      availableInMap: new Map(),

      modals: {

        operator: {
          visibleModal: false
        },

        priority: {
          visibleModal: false
        },

        carrier: {
          visibleModal: false
        },

        shopOrderStatus: {
          visibleModal: false
        },

        comment: {
          visibleModal: false
        },

        problemWithRealization: {
          visibleModal: false
        },

        parcelValue: {
          visibleModal: false
        },

        realParcelValue: {
          visibleModal: false
        },

        massChangeProductStatus: {
          visibleModal: false
        },

        invoiceVat: {

          visibleModal: false

        },

        addProduct: {

          visibleModal: false,
          isPrivate: false,

        },

        massWarehouseInfo: {

          visibleModal: false,

        },

        manualCorrection: {

          visibleModal: false

        },

        changeAddress: false,

        editElementInTable: {

          item: null,
          title: "",
          visibleModal: false,
          element: "",
          elementName: "",
          type: ""

        },

        addTracking: {

          visibleModal: false,

          form: {
            carrierOrderId: "",
            trackingNumber: "",
            trackingUrl: ""
          }

        }

      },

      selectors: {

        priority: {
          options: [
            "0",
            "1",
            "2"
          ]
        },

        shopOrderStatus: {
          options: [
            "ORDER_PLACED",
            "ORDER_IN_PROGRESS",
            "ORDER_COMPLETED",
            "ORDER_CANCELED"
          ]
        }

      },

      form: {

        contactWithOrderProcessing: {
          description: ''
        },

        comment: {

          description: '',
          forLogistics: false

        },

        massChangeProductStatus: {
          productIds: [],
          status: ''
        },

        addProduct: {
          sku: "",
          productVariationId: '',
          amount: 0
        },

        massWarehouseInfo: {

          productsIds: [],
          warehouseInvoiceId: '',
          warehouseOrderId: '',
          warehousePrice: 0

        },

        manualCorrection: {

          language: 'pl',
          serviceId: '',
          previewUrl: '',
          price: 0,
          hash: ''

        }

      },

      shopOrderProductStatuses: null,
      loadedProductWithVariation: null,
      taxes: null,
      taxesIds: [],

      carrierOrders: null,
      tpayAmount: null
    }
  },

  // filters: {
  //   replaceCommaWithDot: function (value) {
  //     return value.replace('.', ',');
  //   }
  // },

  methods: {

    getAllCountries() {
      return allCountries;
    },

    openCreateInvoiceCorrectionModalManual(manual, language) {
      if (manual) {
        this.modals.manualCorrection.language = language
        this.modals.manualCorrection.visibleModal = true
        return
      }

      this.createInvoiceCorrection(language)
    },

    openInvoiceVATPreview() {
      if (this.invoiceVats.length === 1) {
        window.open(this.invoiceVats[0].previewUrl, '_blank').focus();
        return
      }

      this.$refs.invoiceModalRef.openModal(this.invoiceVats)
    },

    openInvoiceCorrectionPreview() {
      if (this.invoiceCorrections.length === 1) {
        window.open(this.invoiceCorrections[0].previewUrl, '_blank').focus();
        return
      }

      this.$refs.invoiceModalRef.openModal(this.invoiceCorrections)
    },

    deliveryAddress() {
      if (!this.$refs.deliveryAddressTable) {
        return null
      }

      return this.$refs.deliveryAddressTable.deliveryAddress()
    },

    async removeCarrierOrder(carrierOrder) {
      try {
        await axios.delete(`/carrier/order/${carrierOrder.id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        toastHelper.success("Pomyślnie usunięto link śledzenia")
        await this.loadAll(this.shopOrder.id, true)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    shippingCostDifference() {
      return this.shopOrder.shippingCostDifferenceTaxExcluded
    },

    backUrl() {
      if (this.customerId) {
        return `/dashboard/core/customers/orders/${this.customerId}`
      }

      return `/dashboard/core/orders?${Object.entries(this.$route.query).map(([key, value]) => `${key}=${value}`).join('&')}`;
    },

    isPaczkomatCarrier() {
      const name = this.getOrderCarrierName().toLowerCase()
      return name.includes('paczkomat')
    },

    getOrderCarrierName() {
      const carrier = this.getCarrier(this.shopOrder.carrierId)
      return (carrier ? carrier.name : "") || ""
    },

    getOperatorName(product) {
      const carrierOrder = this.getCarrierOrder(product.carrierOrderId)
      if (!carrierOrder) {
        return ""
      }

      const carrierOperator = this.getCarrierOperator(carrierOrder.operator)
      if (!carrierOperator) {
        return ""
      }

      return carrierOperator.name
    },

    setToFirstPageAndRefresh(table, refName) {
      this.paginationHelper.setToFirstPageAndRefresh(this, table, refName)
    },

    async sendWaybills(carrierOrderId, input) {
      const file = input.target.files[0];

      const formData = new FormData();
      formData.append('file', file);
      formData.append('carrierOrderId', carrierOrderId)

      try {
        await axios.post(`/carrier/order/upload-waybill-pdf`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        Swal.fire("Sukces!", "List przewozowy został wgrany!", "success")
            .then(async () => await this.loadAll(this.shopOrder.id, true));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core-orders.details.title'),
          active: true
        }
      ]
    },

    async loadShopOrder(shopOrderId) {
      const { data } = await axios.get(`/shop-order/fetch`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "shopOrderId": shopOrderId,
        }
      });

      this.shopOrder = data
      await this.loadTPayAmount()
      await this.loadShop()
      await this.loadCustomer()
      await this.loadInpostParcelLockerAddress()
    },

    async loadTPayAmount() {
      if (this.shopOrder.allegroAccountType !== 'EXTERNAL') {
        return
      }

      try {
        const { data } = await axios.get(`/tpay/shop-order/${this.shopOrder.id}/amount-paid`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.tpayAmount = data
      } catch (error) {
        // ignored
      }
    },

    async getWaybillUrl(carrierOrder) {
      const carrierOperator = this.getCarrierOperator(carrierOrder.operator)
      if (!carrierOperator) {
        return ''
      }

      let service = ''
      if (carrierOperator.name.toLowerCase().includes('inpost') || carrierOperator.name.toLowerCase().includes('paczkomat')) {
        service = 'inpost'
      } else if (carrierOperator.name.toLowerCase().includes('dpd')) {
        service = 'dpd'
      }

      try {
        const { data } = await axios.get(`/logistics/${service}/get-waybill-pdf-url/${carrierOrder.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        window.location = data.url
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadInpostParcelLockerAddress() {
      if (!this.shopOrder.parcelLockerCode) {
        return
      }

      try {
        const {data} = await axios.get(`/logistics/inpost/parcel-locker-address/${this.shopOrder.parcelLockerCode}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.inpostParcelLockerAddress = data
      } catch (error) {
        // ignored
      }
    },

    async loadShop() {
      try {
        const {data} = await axios.get(`/shop/${this.shopOrder.shopId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shop = data
        await this.loadMarket()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarket() {
      try {
        const {data} = await axios.get(`/market/${this.shop.marketId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.market = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadCustomer() {
      try {
        const { data } = await axios.get(`/customer/fetch`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            customerId: this.shopOrder.customerId
          }
        });

        this.customer = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadCarriers() {
      try {
        const { data } = await axios.get(`/carrier`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.carriersIds = []
        for (let carrier of data) {
          this.carriersIds.push(carrier.id);
        }

        this.carriers = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getCarrier(id) {
      if (!this.carriers) {
        return {}
      }

      const carrier = this.carriers.get(id)
      if (!carrier) {
        return {}
      }

      return carrier
    },

    async loadOperator() {
      if (!this.shopOrder || !this.shopOrder.operatorUserId) {
        return
      }

      const json = JSON.stringify({
        "ids": [this.shopOrder.operatorUserId]
      });

      const { data } = await axios.post(`/user`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.operator = data[0]
    },

    async loadOrderPayment() {
      const { data } = await axios.get(`/shop-order/payment`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "shopOrderId": this.shopOrder.id,
        }
      });

      this.orderPayment = data
    },

    getProductsFields() {
      return [
        {key: "name", slot: true, label: 'Produkt'},
        {key: "alternativeProducts", slot: true, label: 'Alternatywne produkty'},
        {key: "quantity", slot: true, label: 'Ilość'},
        {
          key: 'warehouseInvoiceId', slot: true, label: 'FV Hurt.', formatter: value => {
            if (!value) {
              return "-"
            }

            return value
          }
        },
        {
          key: 'warehouseOrderId', slot: true, label: 'Nr zam hurtowni'
        },
        {
          key: 'wholesalePrice', slot: true, label: this.$t("order-details.wholesale-price")
        },
        {
          key: 'warehousePrice', slot: true, label: this.$t("order-details.warehouse-price")
        },
        {
          key: "unitPriceTaxExcluded", slot: true, label: 'Cena netto (1szt)'
        },
        {
          key: "unitPriceTaxIncluded", slot: true, label: 'Cena brutto (1szt)'
        },
        {key: 'tax', slot: true, label: 'Stawka VAT'},
        {key: "status", slot: true, label: 'Status'},
        {key: "trackingUrl", slot: true, label: 'Link śledzenia'},
        {key: "trackingNumber", slot: true, label: 'Numer śledzenia'},
        {key: "warehouse", slot: true, label: 'Hurtownia'},
        {key: "warehouseEcat", slot: true, label: 'Magazyn ECAT'},
        {key: "broadcast", slot: true, label: 'Nadawanie'},
        {key: "action", slot: true, label: 'Akcje'}
      ]
    },

    async loadOrderProducts() {
      let page = this.productsTable.currentPage - 1;
      if (page > 0) {
        page = this.productsTable.currentPage * this.productsTable.perPage - this.productsTable.perPage;
      }

      const {data} = await axios.get(`/shop-order/product/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "shopOrderId": this.shopOrder.id,
          "page": page,
          "size": this.productsTable.perPage
        }
      });

      this.productsTable.items = data.products;
      this.productsTable.totalRows = data.count;
      this.productsTable.rows = data.count;

      await this.loadCarrierOrders()
      await this.loadDepotSectionStocks()
      await this.loadProductVariationsStockReservationAmount()
      await this.loadAlternativeProducts()

      return this.productsTable.items;
    },

    async loadDepotSectionStocks() {
      try {
        const ids = this.productsTable.items.map(product => product.productVariationId);
        const json = {
          productVariationIds: ids
        }

        const {data} = await axios.post(`/depot/section/stock/product-variation-stock-summary`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.depotSectionStocks = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadAlternativeProducts() {
      try {
        const ids = this.productsTable.items.map(product => product.productVariationId);
        const json = {
          ids: ids
        }

        const { data } = await axios.post(`/product/alternative-products`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        for (const key in data.data) {
          const value = data.data[key];
          if (!this.alternativeProducts.has(key)) {
            this.alternativeProducts.set(key, []);
          }

          value.forEach(item => {
            this.alternativeProducts.get(key).push(item);
          });
        }
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getAlternativeProducts(productVariationId) {
      if (!this.alternativeProducts) {
        return []
      }

      return this.alternativeProducts.get(productVariationId) || []
    },

    getDepotSectionStocks(productVariationId) {
      return this.depotSectionStocks.filter(element => element.productVariationId === productVariationId)
    },

    getDepotSectionStockDepots(productVariationId) {
      const uniqueDepotIds = new Map();
      const uniqueStocks = [];

      for (const element of this.depotSectionStocks) {
        if (element.productVariationId === productVariationId) {
          if (!uniqueDepotIds.has(element.depotId)) {
            uniqueDepotIds.set(element.depotId, true);
            uniqueStocks.push(element);
          }
        }
      }

      return uniqueStocks;
    },

    getSumDepotStocks(productVariationId) {
      if (!this.depotSectionStocks) {
        return 0
      }

      let quantity = 0
      for (const depotStock of this.depotSectionStocks) {
        if (depotStock.productVariationId === productVariationId) {
          quantity += depotStock.quantity
        }
      }

      return quantity
    },

    getSumDepotSectionStocks(depotId, productVariationId) {
      if (!this.depotSectionStocks) {
        return 0
      }

      let quantity = 0
      for (const depotStock of this.depotSectionStocks) {
        if (depotStock.depotId === depotId && depotStock.productVariationId === productVariationId) {
          quantity += depotStock.quantity
        }
      }

      return quantity
    },

    async loadProductVariationsStockReservationAmount() {
      try {
        const ids = this.productsTable.items.map(product => product.productVariationId);
        const json = {
          productVariationIds: ids
        }

        if (ids.length === 0) {
          return
        }

        const { data } = await axios.post(`/product/variation/stock-reservation/amount`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.productVariationsStockReservationAmount = new Map(data.map((obj) => [obj.productVariationId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getProductVariationStockReservation(productVariationId) {
      if (!this.productVariationsStockReservationAmount) {
        return 0
      }

      return this.productVariationsStockReservationAmount.get(productVariationId)?.reservedAmount || 0
    },

    async loadCarrierOrders() {
      try {
        const ids = [];

        for (const productTable of this.productsTable.items) {
          if (productTable.carrierOrderId) {
            ids.push(productTable.carrierOrderId);
          }
        }

        const json = JSON.stringify({
          ids: ids
        });

        const { data } = await axios.post(`/carrier/order/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.carrierOrders = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadCarrierOperatorsNames() {
      try {
        const { data } = await axios.get(`/carrier/order/operators/names`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.carrierOperatorsNames = new Map(data.operators.map((obj) => [obj.operator, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getCarrierOperator(operator) {
      if (!this.carrierOperatorsNames) {
        return null
      }

      return this.carrierOperatorsNames.get(operator) || null
    },

    getCarrierOrder(id) {
      if (!this.carrierOrders) {
        return null
      }

      return this.carrierOrders.get(id) || null
    },

    async loadComments() {
      return this.loadOrderComments(false)
    },

    async loadCommentsForLogistics() {
      return this.loadOrderComments(true)
    },

    async loadOrderComments(forLogistics) {
      let table = forLogistics ? this.commentsForLogisticsTable : this.commentsTable;

      let page = table.currentPage - 1;
      if (page > 0) {
        page = table.currentPage * table.perPage - table.perPage;
      }

      const {data} = await axios.get(`/shop-order/comment/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          shopOrderId: this.shopOrder.id,
          forLogistics: forLogistics,
          showDeleted: false,
          page: page,
          size: table.perPage
        }
      });

      table.items = data.comments;
      table.totalRows = data.count;
      table.rows = data.count;

      await this.loadCommentAuthors(forLogistics)

      if (this.mounted) {
        this.$refs.commentsTable.refresh()
      }

      return table.items;
    },

    paymentStatusText(paymentStatus) {
      switch (paymentStatus) {
        case 'WAITING':
          return 'Do weryfikacji'
        case 'SUCCESS':
          return 'Opłacona'
        case 'FAILED':
          return 'Nieopłacona'
      }
    },

    paymentStatusColor(paymentStatus) {
      switch (paymentStatus) {
        case 'WAITING':
          return 'bg-warning'
        case 'SUCCESS':
          return 'bg-success'
        case 'FAILED':
          return 'bg-danger'
      }
    },

    priorityText(priority) {
      switch (priority) {
        case 0:
          return 'Niski'
        case 1:
          return 'Średni'
        case 2:
          return 'Wysoki'
      }

      return 'Nieznany'
    },

    priorityColor(priority) {
      switch (priority) {
        case 0:
          return 'text-success'
        case 1:
          return 'text-warning'
        case 2:
          return 'text-danger'
      }

      return 'text-info'
    },

    editOrderCarrier() {
      if (!this.editingShopOrder.carrierId) {
        Swal.fire("Błąd", "Wybierz sposób wysyłki!", "error");
        return
      }

      const carrier = this.getCarrier(this.editingShopOrder.carrierId)
      if (!carrier) {
        Swal.fire("Błąd", "Wybierz sposób wysyłki!", "error");
        return
      }

      if (carrier.name.toLowerCase().includes('paczkomat') && !this.editingShopOrder.parcelLockerCode) {
        Swal.fire("Błąd", "Wprowadź kod paczkomatu", "error");
        return
      }

      this.editOrder(this.modals.carrier, 'Pomyślnie zmieniono sposób wysyłki')
    },

    editOrder(modal, successMessage) {
      axios.put(`/shop-order`, this.editingShopOrder, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(async () => {
        this.$bvToast.toast(successMessage, {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        modal.visibleModal = false
        await this.loadAll(this.shopOrder.id, true)
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    setEditingShopOrder() {
      this.editingShopOrder = Object.assign({}, this.shopOrder)
    },

    editOperator() {
      this.setEditingShopOrder()
      this.modals.operator.visibleModal = true
    },

    editPriority() {
      this.setEditingShopOrder()
      this.modals.priority.visibleModal = true
    },

    editCarrier() {
      this.setEditingShopOrder()
      this.modals.carrier.visibleModal = true
    },

    editShopOrderStatus() {
      this.setEditingShopOrder()
      this.modals.shopOrderStatus.visibleModal = true
    },

    addProblemWithRealization() {
      this.setEditingShopOrder()
      this.modals.problemWithRealization.visibleModal = true
    },

    editParcelValue() {
      this.setEditingShopOrder()
      this.modals.parcelValue.visibleModal = true
    },

    editRealParcelValue() {
      this.setEditingShopOrder()
      this.modals.realParcelValue.visibleModal = true
    },

    hideCommentModal() {
      this.form.comment = {
        description: '',
        forLogistics: false
      }
      this.modals.comment.visibleModal = false
    },

    hideMassWarehouseModal() {
      this.modals.massWarehouseInfo.visibleModal = false
    },

    addMassWarehouseInfo() {
      this.$v.form.massWarehouseInfo.$touch();

      if (this.$v.form.massWarehouseInfo.$invalid) {
        return;
      }

      if (this.form.massWarehouseInfo.productsIds.length === 0) {
        this.$bvToast.toast('Wybierz przynajmniej jeden produkt!', {
          title: this.$t('message.error'),
          variant: 'danger',
          solid: true
        });

        return;
      }

      const json = JSON.stringify({
        "shopOrderId": this.shopOrder.id,
        "checkedProductIds": this.form.massWarehouseInfo.productsIds,
        "warehouseInvoiceId": this.form.massWarehouseInfo.warehouseInvoiceId,
        "warehouseOrderId": this.form.massWarehouseInfo.warehouseOrderId,
        "warehousePrice": this.form.massWarehouseInfo.warehousePrice
      })

      axios.post(`/logistics/mass-warehouse-info-save`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(async () => {
        this.$bvToast.toast('Pomyślnie zmieniono dodatkowe informacje o zamówionych produktach z hurtowni', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.modals.massWarehouseInfo.visibleModal = false
        await this.loadAll(this.shopOrder.id, true)
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    hideProblemWithRealizationModal() {
      this.modals.problemWithRealization.visibleModal = false
    },

    hideAddProductModal() {
      this.modals.addProduct.visibleModal = false
      this.modals.addProduct.private = false
      this.form.addProduct = {
        id: '',
        productVariationId: '',
        amount: 0
      }

      this.productVariationsTable.elements = []
    },

    addProduct() {
      // stop here if form is invalid
      this.$v.form.addProduct.$touch();

      if (this.$v.form.addProduct.$invalid) {
        return;
      }

      if (this.form.addProduct.amount <= 0) {
        this.$bvToast.toast('Ilość nie może być mniejsza niż 0', {
          title: this.$t('message.error'),
          variant: 'danger',
          solid: true
        });
        return;
      }

      const json = JSON.stringify({
        "shopOrderId": this.shopOrder.id,
        "productId": this.loadedProductWithVariation.id,
        "sku": this.form.addProduct.sku,
        "productVariationId": this.form.addProduct.productVariationId,
        "amount": this.form.addProduct.amount
        // "isPrivate": this.modals.addProduct.isPrivate
      });

      axios.put(`/shop-order/product`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(async () => {
        this.$bvToast.toast(this.modals.addProduct.isPrivate ? 'Pomyślnie dodano produkt stacjonarny' : 'Pomyślnie dodano produkt', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        await this.loadAll(this.shopOrder.id, true)
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    openModalMassProductStatusChange() {
      this.modals.massChangeProductStatus.visibleModal = true
      this.form.massChangeProductStatus = {
        status: '',
        productIds: []
      }
    },

    openAddTrackingModal(carrierOrderId) {
      this.modals.addTracking.visibleModal = true
      this.modals.addTracking.form.carrierOrderId = carrierOrderId
      this.modals.addTracking.form.trackingNumber = ""
      this.modals.addTracking.form.trackingUrl = ""
    },

    addTracking() {
      this.$v.modals.addTracking.form.$touch();
      if (this.$v.modals.addTracking.form.$invalid) {
        return;
      }

      const json = JSON.stringify(this.modals.addTracking.form)

      axios.post(`/carrier/order/set-tracking`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        Swal.fire("Sukces!", "Pomyślnie dodano numer śledzenia", "success").then(async () => await this.loadAll(this.shopOrder.id, false));
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    productsQuantitySum() {
      let quantity = 0;

      for (const orderProduct of this.productsTable.items) {
        quantity += orderProduct.quantity
      }

      return quantity
    },

    productsPriceSum() {
      let price = 0.0;

      for (const orderProduct of this.productsTable.items) {
        price += orderProduct.price * orderProduct.quantity
      }

      return priceHelper.format(price, this.market.currency)
    },

    hideEditElementInTableModal() {
      this.modals.editElementInTable.visibleModal = false
      this.modals.editElementInTable.item = null
      this.modals.editElementInTable.title = ""
      this.modals.editElementInTable.element = ""
      this.modals.editElementInTable.elementName = ""
      this.modals.editElementInTable.type = ""
    },

    openEditElementInTableModal(item, element, elementName, type, title) {
      this.modals.editElementInTable.item = Object.assign({}, item)
      this.modals.editElementInTable.element = element
      this.modals.editElementInTable.title = title
      this.modals.editElementInTable.elementName = elementName
      this.modals.editElementInTable.type = type
      this.modals.editElementInTable.visibleModal = true
    },

    editShopOrderProduct() {
      this.submitted = true;

      const json = JSON.stringify(this.modals.editElementInTable.item);

      axios.post(`/shop-order/product/edit`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(async () => {
        this.$bvToast.toast('Pomyślnie zaktualizowano wartość!', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.hideEditElementInTableModal()
        await this.loadAll(this.shopOrder.id, true)
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    commentsFields() {
      return [
        {key: "createdAt", label: 'Utworzony', formatter: dateUtil.asDateTimeNormal},
        {key: "description", label: 'Treść'},
        {key: "author", slot: true, label: 'Autor'},
        {key: "action", slot: true, label: 'Akcje'}
      ]
    },

    variationsFields() {
      return [
        {key: 'element', label: ""},
        {key: 'id', label: 'ID Wariacji'},
        {key: 'ean', label: 'EAN'},
        {key: 'wholesalePrice', label: 'Cena hurtowni'},
        {key: 'availableIn', label: 'Dostępnych sztuk'} // https://bitbucket.org/ecat2/core-2.0/src/ef659639a86a8f31a9731deaef0409c81a88f7d3/templates/logistic/Order/confirm_product.html.twig?at=master#lines-42
      ]
    },

    commentsForLogisticsFields() {
      return [
        {key: "createdAt", label: 'Utworzony', formatter: dateUtil.asDateTimeNormal},
        {key: "description", label: 'Treść'},
        {key: "author", slot: true, label: 'Autor'}
      ]
    },

    async createComment() {
      // stop here if form is invalid
      this.$v.form.comment.$touch();

      if (this.$v.form.comment.$invalid) {
        return;
      }

      try {
        const json = JSON.stringify({
          "shopOrderId": this.shopOrder.id,
          "description": this.form.comment.description,
          "forLogistics": this.form.comment.forLogistics
        });

        await axios.put(`/shop-order/comment`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.$bvToast.toast('Pomyślnie dodano komentarz', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.setToFirstPageAndRefresh(this.commentsTable, "commentsTable")
        if (this.form.comment.forLogistics) {
          this.setToFirstPageAndRefresh(this.commentsForLogisticsTable, "commentsForLogistics")
        }

        this.hideCommentModal()
      } catch (error) {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      }
    },

    deleteComment(comment) {
      axios.delete(`/shop-order/comment`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {"id": comment.id}
      }).then(() => {
        this.submitted = false;
        this.$bvToast.toast('Pomyślnie usunięto komentarz', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.setToFirstPageAndRefresh(this.commentsTable, "commentsTable")
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    async loadCommentAuthors(forLogistics) {
      const table = forLogistics ? this.commentsForLogisticsTable : this.commentsTable
      if (table.items.length === 0) {
        return
      }

      const ids = []
      for (const comment of table.items) {
        ids.push(comment.userId)
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/user`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (forLogistics) {
        this.commentForLogisticsAuthors = new Map(result.data.map((obj) => [obj.id, obj]));
      } else {
        this.commentAuthors = new Map(result.data.map((obj) => [obj.id, obj]));
      }
    },

    getCommentAuthor(id, forLogistics) {
      const authors = forLogistics ? this.commentForLogisticsAuthors : this.commentAuthors
      if (!authors) {
        return null
      }

      const author = authors.get(id)
      if (!author) {
        return null
      }

      return author
    },

    async loadShopOrderProductStatus() {
      const { data } = await axios.get(`/shop-order/product/status`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.shopOrderProductStatuses = data
    },

    massProductChangeStatus() {
      this.$v.form.massChangeProductStatus.$touch();

      if (this.$v.form.massChangeProductStatus.$invalid) {
        return;
      }

      if (this.form.massChangeProductStatus.productIds.length === 0) {
        this.$bvToast.toast('Wybierz przynajmniej jeden produkt!', {
          title: this.$t('message.error'),
          variant: 'danger',
          solid: true
        });

        return;
      }

      const json = JSON.stringify({
        "ids": this.form.massChangeProductStatus.productIds,
        "status": this.form.massChangeProductStatus.status
      })

      axios.post(`/shop-order/product/update-status`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(async () => {
        this.$bvToast.toast('Pomyślnie zmieniono statusy produktów', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.modals.massChangeProductStatus.visibleModal = false
        await this.loadAll(this.shopOrder.id, false)
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    async loadInvoice(invoiceType) {
      try {
        const {data} = await axios.get(`/invoice`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "shopOrderId": this.shopOrder.id,
            "type": invoiceType
          }
        });

        if (invoiceType === 'VAT') {
          this.invoiceVats = data
        } else {
          this.invoiceCorrections = data
        }
      } catch (error) {
        console.log(error)

        if (invoiceType === 'VAT') {
          this.invoiceVats = []
        } else {
          this.invoiceCorrections = []
        }
      }
    },

    async loadCustomerAddress() {
      try {
        const {data} = await axios.get(`/customer/address`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "customerAddressId": this.shopOrder.customerAddressId
          }
        });

        this.customerAddress = data
        await this.loadInvoiceAddress()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadInvoiceAddress() {
      try {
        const result = await axios.get(`/address/${this.customerAddress.invoiceId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.invoiceAddress = result.data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    createInvoice(language, customerCountryLanguage, type) {
      const json = JSON.stringify({
        "shopOrderId": this.shopOrder.id,
        language: language,
        customerCountryLanguage: customerCountryLanguage,
        "invoiceType": type
      })

      axios.post(`/invoice`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(async () => {
        this.$bvToast.toast(type === 'VAT' ? 'Utworzono fakturę' : 'Utworzono korektę faktury', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        if (type === 'VAT') {
          await this.loadInvoice("VAT")
          this.modals.invoiceVat.visibleModal = false
        } else {
          await this.loadInvoice("CORRECTION")
        }
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    createInvoiceCorrectionManually() {
      // stop here if form is invalid
      this.$v.form.manualCorrection.$touch();

      if (this.$v.form.manualCorrection.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "shopOrderId": this.shopOrder.id,
        language: this.form.manualCorrection.language,
        "serviceId": this.form.manualCorrection.serviceId,
        "previewUrl": this.form.manualCorrection.previewUrl,
        "price": this.form.manualCorrection.price,
        "hash": this.form.manualCorrection.hash,
      })

      axios.post(`/invoice/manual-correction`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast('Utworzono manualną korektę', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.loadInvoice('CORRECTION')
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    hideManualCorrectionModal() {
      this.modals.manualCorrection.visibleModal = false;
      this.form.manualCorrection = {
        serviceId: '',
        previewUrl: '',
        price: 0,
        hash: ''
      }
    },

    createInvoiceCorrection(language) {
      this.createInvoice(language, false, 'CORRECTION')
    },

    setPaymentAsPaid(invoice, paymentSystem) {
      const json = JSON.stringify({
        "shopOrderId": this.shopOrder.id,
        "createInvoice": invoice,
        "paymentSystem": paymentSystem
      })

      axios.post(`/shop-order/payment`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(async () => {
        this.$bvToast.toast(invoice ? 'Poprawnie ustawiono płatność jako opłaconą z fakturą' : 'Poprawnie ustawiono płatność jako opłaconą bez faktury', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        await this.loadAll(this.shopOrder.id, false)
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    updateInvoiceAddress(createInvoice, type, paid) {
      this.$v.invoiceAddress.$touch();

      if (this.$v.invoiceAddress.$invalid) {
        return;
      }

      const json = JSON.stringify(this.invoiceAddress)

      axios.post(`/address`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        if (createInvoice) {
          if (paid) {
            this.setPaymentAsPaid(true, '')
          } else {
            this.createInvoice(null, false, type)
            this.createInvoice(null, true, type)
          }
        } else {
          this.$bvToast.toast('Poprawnie zaktualizowano dane do faktury', {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          this.hideInvoiceModal()
        }
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    hideInvoiceModal() {
      this.modals.changeAddress = false
      this.modals.invoiceVat.visibleModal = false
    },

    openInvoiceModalAsPreviewAddress() {
      this.modals.changeAddress = true
      this.modals.invoiceVat.visibleModal = true
    },

    openAddProductModal(isPrivate) {
      this.modals.addProduct.visibleModal = true
      this.modals.addProduct.isPrivate = isPrivate
    },

    async loadVariations() {
      try {
        this.loadingVariations = true
        this.loadedProductWithVariation = null
        let json = JSON.stringify({"sku": this.form.addProduct.sku})

        const result = await axios.post(`/product/variation/by-sku`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.loadingVariations = false
        this.productVariationsTable.elements = result.data
        this.productVariationsTable.totalRows = result.data.length;
        this.productVariationsTable.rows = result.data.length;

        this.$refs.productVariationsTable.refresh();
        this.form.addProduct.productVariationId = ''
      } catch (error) {
        this.loadingVariations = false
        console.log(error)
      }

      await this.loadAvailableIn()
      try {
        await this.loadProductWithVariation()
      } catch (error) {
        console.log(error)
      }
    },

    async loadAvailableIn() {
      try {
        const json = {
          ids: this.productVariationsTable.elements.map(productVariation => productVariation.id)
        }

        const {data} = await axios.post(`/product/variation/available-in`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.availableInMap = new Map(data.map((obj) => [obj.productVariationId, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    getAvailableIn(productVariationId) {
      return this.availableInMap.get(productVariationId)
    },

    async loadProductWithVariation() {
      const result = await axios.get(`/product/fetch/by-sku`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "sku": this.form.addProduct.sku
        }
      })

      this.loadedProductWithVariation = result.data
    },

    async loadTaxes() {
      const { data } = await axios.get(`/tax`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      const ids = [];
      for (let tax of data) {
        ids.push(tax.id);
      }

      this.taxesIds = ids
      this.taxes = new Map(data.map((obj) => [obj.id, obj]));
    },

    getTaxRate(taxId) {
      if (!this.taxes) {
        return ""
      }

      const tax = this.taxes.get(taxId);
      if (!tax) {
        return "";
      }

      return tax.rate;
    },

    getPathNameToSendPackage(carrierOrder, isProductCarrier) {
      if (!this.shopOrder) {
        return ''
      }

      let name = ""

      if (isProductCarrier && carrierOrder) {
        const carrierOperator = this.getCarrierOperator(carrierOrder.operator)
        if (!carrierOperator) {
          return ''
        }

        name = carrierOperator.name.toLowerCase()
      } else {
        name = this.getOrderCarrierName().toLowerCase()
      }

      let query = ''
      query += "?orderId=" + this.shopOrder.id

      if (name.includes('inpost') || name.includes('paczkomat')) {
        return '/dashboard/core/shipping/inpost' + query
      } else if (name.includes('dpd')) {
        return '/dashboard/core/shipping/dpd' + query
      }

      return ''

      // {% if carrier is not null %}-->
      // {% if carrier.id == 1 %}-->
      // {# {% set pathName = 'inpost_homepage' %} #}-->
      // {% set shipmentOrderPath = 'furgonetka_homepage' %}-->
      // {% set waybillPath = 'furgonetka_waybill' %}-->
      // {% elseif carrier.id == 2 %}-->
      // {% set shipmentOrderPath = 'inpost_homepage' %}-->
      // {% set waybillPath = 'inpost_waybill' %}-->
      // {% else %}-->
      // {% set shipmentOrderPath = 'dpd_homepage' %}-->
      // {% set waybillPath = 'dpd_waybill' %}-->
      // {% endif %}-->
      // {% endif %}-->
    },

    getCarrierOrderNameToShipment(item) {
      if (item.carrierOrderId) {
        const carrierOrder = this.getCarrierOrder(item.carrierOrderId)
        if (carrierOrder && carrierOrder.trackingUrl) {
          const carrierOperator = this.getCarrierOperator(carrierOrder.operator)
          if (carrierOperator) {
            return carrierOperator.name
          }
        }
      }

      const orderCarrierName = this.getOrderCarrierName()
      const nameToLower = orderCarrierName.toLowerCase()

      if (nameToLower.includes('inpost') || nameToLower.includes('paczkomat')) {
        return orderCarrierName
      } else if (nameToLower.includes('dpd')) {
        return orderCarrierName
      }

      return ""
    },

    async loadAll(shopOrderId, reload = false) {
      await this.loadShopOrder(shopOrderId)
      await this.loadOperator()
      await this.loadCustomerAddress()
      await this.loadOrderPayment()
      await this.loadInvoice('VAT')
      await this.loadInvoice('CORRECTION')

      if (reload) {
        this.setToFirstPageAndRefresh(this.productsTable, "productsTable")
        this.$refs.ordersLogsTable.refresh()
      }
    },

    async loadOnce() {
      await this.loadCarrierOperatorsNames()
      await this.loadCarriers()
      await this.loadTaxes()
      await this.loadShopOrderProductStatus()
    }

  },

  async created() {
    this.customerId = this.$route.query.customerId
    this.loading = true

    try {
      const params = routerHelper.params();
      const shopOrderId = params.shopOrderId;
      if (!shopOrderId) {
        return;
      }

      await this.loadOnce()
      await this.loadAll(shopOrderId)
    } catch (error) {
      console.log(error)
    }

    this.loading = false
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('core-orders.details.title')"/>
    <invoice-modal ref="invoiceModalRef" />
    <create-invoice-correction-modal @select="openCreateInvoiceCorrectionModalManual" ref="createInvoiceCorrectionModalRef" />

    <template v-if="shopOrder && shop && market && customer && !loading">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-xl-12 mb-2">
                  <a :href="backUrl()" class="btn btn-success mr-2">{{ customerId ? "Powrót do listy zamówień klienta" : "Powrót do listy zamówień" }}</a>
                </div>
              </div>

              <div class="row">

                <div class="col-12 col-xl-3">
                  <div class="panel panel-primary full-height font-size-12">
                    <div class="panel-heading text-center">
                      <h4>Szczegóły zamówienia: <span>{{ shopOrder.idAsNumber }}</span></h4>
                    </div>
                    <div class="panel-body">
                      <table class="table table-responsive wrap-text order-table">
                        <tbody>
                        <tr>
                          <th>
                            <span>Numer:</span>
                          </th>
                          <td>
                              <span class="text-info wrap-text">{{
                                  shopOrder.idAsNumber
                                }} {{ shopOrder.shopOrderId ? '(' + shopOrder.shopOrderId + ')' : '' }}

                                <span v-if="deliveryAddress()" :class="`fi fi-${deliveryAddress().country.toLowerCase()}`" style="font-size: 20px;" />
                              </span>
                          </td>
                          <td>
<!--                            <button class="btn btn-primary btn-xs" @click="clipboardHelper.copy(shopOrder.idAsNumber)">-->
                              <i class="mdi mdi-clipboard clickable-element font-size-18" @click="clipboardHelper.copy(shopOrder.idAsNumber)"></i>
<!--                            </button>-->
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <span>Sklep:</span>
                          </th>
                          <td>
                            <a class="text-info wrap-text"><span>{{ shop.name }} {{
                                shop.domain ? '(' + shop.domain + ')' : ''
                              }}</span></a>
                          </td>
                          <td>
<!--                            <button class="btn btn-primary btn-xs"-->
                              <i class="mdi mdi-clipboard clickable-element font-size-18" @click="clipboardHelper.copy(shop.domain ? shop.domain : shop.name)"></i>
<!--                            </button>-->
                          </td>
                        </tr>
                        <tr v-if="shopOrder.allegroAccountName">
                          <th>
                            <span>Konto allegro: </span>
                          </th>
                          <td>
                            <span class="text-info wrap-text">{{ shopOrder.allegroAccountName }}</span>
                          </td>
                          <td>
<!--                            <button class="btn btn-primary btn-xs"-->

                              <i class="mdi mdi-clipboard clickable-element font-size-18" @click="clipboardHelper.copy(shopOrder.allegroAccountName)"></i>
<!--                            </button>-->
                          </td>
                        </tr>
                        <tr v-if="shopOrder.allegroAccountType">
                          <th>
                            <span>Rodzaj konta allegro:</span>
                          </th>
                          <td>
                            <span class="text-info wrap-text">
                              {{ shopOrder.allegroAccountType === 'EXTERNAL' ? "Zewnętrzny" : shopOrder.allegroAccountType }}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <th>
                            <span>Data: </span>
                          </th>
                          <td>
                            <span class="text-info wrap-text">{{
                                dateUtil.asDateTimeNormal(shopOrder.createdAt)
                              }}</span>
                          </td>
                          <td>

                          </td>
                        </tr>
                        <tr>
                          <th>
                            <span>Adres email: </span>
                          </th>
                          <td>
                            <a :href="`/dashboard/core/orders?orderId=${shopOrder.id}`" class="text-info wrap-text">
                              <span>{{ customer.email }}</span>
                            </a>
                          </td>
                          <td>
<!--                            <button class="btn btn-primary btn-xs">-->
                              <i class="mdi mdi-clipboard clickable-element font-size-18" @click="clipboardHelper.copy(customer.email)"></i>
<!--                            </button>-->
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <span>Sposób wysyłki:</span>
                          </th>
                          <td>
                            <span class="text-info wrap-text">{{ getCarrier(shopOrder.carrierId).name }}</span>
                          </td>
                          <td>
<!--                            <button class="btn btn-success btn-xs" >-->
                              <i class="mdi mdi-pencil clickable-element font-size-18" @click="editCarrier"></i>
<!--                            </button>-->
                          </td>
                        </tr>
                        <tr v-if="shopOrder.parcelLockerCode && isPaczkomatCarrier()">
                          <th>
                            <span>Adres paczkomatu:</span>
                          </th>
                          <td>
                            <span class="text-info wrap-text">{{
                                inpostParcelLockerAddress ? (inpostParcelLockerAddress.address.line1 + ", " + inpostParcelLockerAddress.address.line2 + " (" + shopOrder.parcelLockerCode + ")") : shopOrder.parcelLockerCode
                              }}</span>
                          </td>
                          <td>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <br/>
                      <a :href="`${linkSledzenia}?id=${shopOrder.id}`" class="btn btn-block btn-outline-primary"
                         target="_blank">Widok śledzenia</a>
                      <!--                    <a class="btn btn-block btn-outline-warning">Ustaw jako porzucony koszyk</a>-->
                      <a class="btn btn-block btn-outline-danger" @click="addProblemWithRealization">Zgłoś problem z
                        realizacją</a>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-xl-3">
                  <div class="panel panel-primary full-height font-size-12">
                    <div class="panel-heading text-center">
                      <h4>Status</h4>
                    </div>
                    <div class="panel-body">
                      <table class="table table-responsive wrap-text order-table">
                        <tbody>
                        <tr class="bg-info text-white">
                          <th>
                            Status zamówienia:
                          </th>
                          <td>
                            <span>{{ $t('core-orders.order.status.' + shopOrder.status) }}</span>
                          </td>
                          <td>
<!--                            <button class="btn btn-outline-dark btn-xs m-l-xs" >-->
                              <i class="mdi mdi-pencil clickable-element font-size-18" @click="editShopOrderStatus"></i>
<!--                            </button>-->
                          </td>
                        </tr>
                        <tr :class="paymentStatusColor(shopOrder.sbzPaymentStatus)" class="text-white">
                          <th>
                            Status płatności:
                          </th>
                          <td>
                            <span>{{ paymentStatusText(shopOrder.sbzPaymentStatus) }}</span>
                          </td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Przypisany operator:
                          </th>
                          <td>
                            <span class="text-info">{{ shopOrder.operatorUserId ? operator.email : 'Brak' }}</span>
                          </td>
                          <td>
<!--                            <button class="btn btn-success btn-xs m-l-xs">-->
                              <i class="mdi mdi-pencil clickable-element font-size-18" @click="editOperator"/>
<!--                            </button>-->
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Priorytet:
                          </th>
                          <td>
                          <span class="text-info">
                            <span :class="priorityColor(shopOrder.priority)"
                                  class="text">{{ priorityText(shopOrder.priority) }}</span></span>
                          </td>
                          <td>
<!--                            <button class="btn btn-success btn-xs change-priority-order m-l-xs">-->
                              <i class="mdi mdi-pencil clickable-element font-size-18" @click="editPriority"></i>
<!--                            </button>-->
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <br>
                      <!--                      <a class="btn btn-block btn-outline-primary">Dodaj dopłatę</a>-->

                      <template v-if="orderPayment && orderPayment.status === 'UNPAID' || shopOrder.sbzPaymentStatus === 'WAITING'">
                        <a class="btn btn-block btn-outline-warning" @click="setPaymentAsPaid(false, '')">Zatwierdź
                          wpłatę (bez faktury)</a>
                        <a class="btn btn-block btn-outline-success" @click="setPaymentAsPaid(true, '')">Zatwierdź
                          wpłatę (z fakturą)</a>
                        <a class="btn btn-block btn-outline-info" @click="setPaymentAsPaid(false, 'BANK_CARD')">Zatwierdź
                          wpłatę KARTĄ ze sklepu stacjonarnego</a>
                        <a class="btn btn-block btn-outline-info" @click="setPaymentAsPaid(false, 'CASH')">Zatwierdź
                          wpłatę GOTÓWKĄ ze sklepu stacjonarnego</a>
                      </template>

                      <!--                      <a class="btn btn-block btn-outline-danger">Anuluj płatność</a>-->
                    </div>
                  </div>
                </div>

                <div class="col-12 col-xl-3">
                  <div class="panel panel-primary full-height">
                    <div class="panel-heading text-center">
                      <h4>Faktura</h4>
                    </div>
                    <div class="panel-body">
                      <template v-if="invoiceVats && invoiceVats.length > 0">
                        <a @click="openInvoiceVATPreview" class="btn btn-block btn-outline-primary" target="_blank">Podgląd</a>

                        <button class="btn btn-block btn-outline-primary" @click="$refs.createInvoiceCorrectionModalRef.openModal(true, invoiceVats, invoiceCorrections)">Dodaj korektę wygenerowaną ręcznie</button>

                        <a class="btn btn-block btn-outline-primary" @click="$refs.createInvoiceCorrectionModalRef.openModal(false, invoiceVats, invoiceCorrections)">Wystaw korektę z aktualnymi danymi zamówienia</a>

                        <button class="btn btn-block btn-outline-primary" @click="openInvoiceModalAsPreviewAddress">Dane
                          do faktury
                        </button>

                        <template v-if="invoiceCorrections && invoiceCorrections.length > 0">
                          <a @click="openInvoiceCorrectionPreview" class="btn btn-block btn-outline-primary" target="_blank">Podgląd korekty</a>
                        </template>

                        <button class="btn btn-block btn-outline-danger" @click="$refs.invoiceModalRef.openModalWithDeleteButtonAndCallback(invoiceVats, 'VAT', () => loadInvoice('VAT'))">Usuń fakturę</button>
                        <template v-if="invoiceCorrections && invoiceCorrections.length > 0">
                          <button class="btn btn-block btn-outline-danger" @click="$refs.invoiceModalRef.openModalWithDeleteButtonAndCallback(invoiceCorrections, 'CORRECTION', () => loadInvoice('CORRECTION'))">Usuń korektę fakturę</button>
                        </template>
                      </template>
                      <template v-else>
                        <h4 class="text-center">Nie wystawiono faktury</h4>
                        <a class="btn btn-block btn-outline-primary" @click="modals.invoiceVat.visibleModal = true">Fakturowanie</a>
                      </template>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-xl-3">
                  <div class="panel panel-primary full-height">
                    <div class="panel-heading text-center">
                      <h4>Operacje na produktach</h4>
                    </div>
                    <div class="panel-body">
                      <a class="btn btn-block btn-outline-primary" @click="openModalMassProductStatusChange">Masowa
                        zmiana statusów</a>
                      <a class="btn btn-block btn-outline-primary"
                         @click="modals.massWarehouseInfo.visibleModal = true">Masowe dodawanie informacji z
                        Hurtowni</a>
                      <button class="btn btn-block btn-outline-primary" @click="openAddProductModal(false)">Dodaj nowy
                        produkt
                      </button>
                      <button class="btn btn-block btn-outline-primary" @click="openAddProductModal(true)">Dodaj nowy
                        produkt stacjonarny
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <template v-if="shopOrder.problem">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h3 class="text-danger">Problem z realizacją</h3>
                <hr>
                <p>{{ shopOrder.problem }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="productsTable.items">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h3>Zamówione produkty</h3>

                <ecat-table
                    ref="productsTable"
                    :fields="getProductsFields()"
                    :items="loadOrderProducts"
                    :pagination-top="true"
                    :table="productsTable"
                >
                  <template v-slot:name="{ item }">
                    <div class="ecat-row-width">
                      <div>
                        <a :href="`/dashboard/admin/product/show/${item.productId}`" target="_blank">{{ item.name }}</a>
                      </div>

                      <a
                          v-if="item.auctionAllegroId"
                          :href="`https://allegro.pl/oferta/${item.auctionAllegroId}`"
                          target="_blank"
                      >ID: {{ item.auctionAllegroId }}</a>
                    </div>
                  </template>

                  <template v-slot:alternativeProducts="{ item }">
                    <template v-if="getAlternativeProducts(item.productVariationId).length > 0">
                      <span>Produkt dostępny również w:</span>
                      <p v-for="(alternativeProduct, index) in getAlternativeProducts(item.productVariationId)" :key="index">
                        <a :href="`/dashboard/admin/product/show/${alternativeProduct.productId}`" target="_blank" class="clickable-element">{{ alternativeProduct.warehouseName }} - {{ priceHelper.format(alternativeProduct.wholesalePrice, market.currency) }}</a>
                      </p>
                    </template>
                    <template v-else>
                      Nie dotyczy
                    </template>
                  </template>

                  <template v-slot:tax="{ item }">
                    <div class="ecat-row-width">
                      <p> {{ getTaxRate(item.taxId) }}% </p>

                      <button class="btn btn-sm btn-success"
                              @click="openEditElementInTableModal(item, 'taxId', 'Stawka VAT', 'TAX', 'Zmiana stawki VAT')">
                        <i class="mdi mdi-pencil"></i>
                      </button>
                    </div>
                  </template>

                  <template v-slot:quantity="{ item }">
                    <div class="ecat-row-width">
                      <p>{{ item.quantity }}</p>

                      <button class="btn btn-sm btn-success"
                              @click="openEditElementInTableModal(item, 'quantity', 'Ilość produktu', 'INT', 'Zmiana ilości produktu')">
                        <i class="mdi mdi-pencil"></i>
                      </button>
                    </div>
                  </template>

                  <template v-slot:warehouseInvoiceId="{ item }">
                    <div class="ecat-row-width">
                      <p v-if="item.warehouseInvoiceId">{{ item.warehouseInvoiceId }}</p>
                      <p v-else>-</p>

                      <button class="btn btn-sm btn-success"
                              @click="openEditElementInTableModal(item, 'warehouseInvoiceId', 'Faktura hurtowni', 'STRING', 'Zmiana faktury hurtowni')">
                        <i class="mdi mdi-pencil"></i>
                      </button>
                    </div>
                  </template>

                  <template v-slot:warehouseOrderId="{ item }">
                    <div class="ecat-row-width">
                      <p v-if="item.warehouseOrderId">{{ item.warehouseOrderId }}</p>
                      <p v-else>-</p>

                      <button class="btn btn-sm btn-success"
                              @click="openEditElementInTableModal(item, 'warehouseOrderId', 'Nr zamówienia hurtowni', 'STRING', 'Zmiana nr zamówienia hurtowni')">
                        <i class="mdi mdi-pencil"></i>
                      </button>
                    </div>
                  </template>

                  <template v-slot:wholesalePrice="{ item }">
                    <div class="ecat-row-width">
                      <p>{{ priceHelper.format(item.wholesalePrice, market.currency) }}</p>

                      <button class="btn btn-sm btn-success"
                              @click="openEditElementInTableModal(item, 'wholesalePrice', 'Cena hurt. prod', 'INT', 'Zmiana ceny hurt. prod')">
                        <i class="mdi mdi-pencil"></i>
                      </button>
                    </div>
                  </template>

                  <template v-slot:warehousePrice="{ item }">
                    <div class="ecat-row-width">
                      <p>{{ priceHelper.format(item.warehousePrice, market.currency) }}</p>

<!--                      <button class="btn btn-sm btn-success"-->
<!--                              @click="openEditElementInTableModal(item, 'warehousePrice', 'Wart. zam. hurtowni', 'INT', 'Zmiana wart. zam. hurtowni')">-->
<!--                        <i class="mdi mdi-pencil"></i>-->
<!--                      </button>-->
                    </div>
                  </template>

                  <template v-slot:unitPriceTaxExcluded="{ item }">
                    <div class="ecat-row-width">
                      <p>{{ priceHelper.format(item.unitPriceTaxExcluded, market.currency) }}</p>

                      <button class="btn btn-sm btn-success"
                              @click="openEditElementInTableModal(item, 'unitPriceTaxExcluded', 'Cena netto (1szt)', 'INT', 'Zmiana ceny netto (1szt)')">
                        <i class="mdi mdi-pencil"></i>
                      </button>
                    </div>
                  </template>

                  <template v-slot:unitPriceTaxIncluded="{ item }">
                    <div class="ecat-row-width">
                      <p>{{ priceHelper.format(item.unitPriceTaxIncluded, market.currency) }}</p>

                      <button class="btn btn-sm btn-success"
                              @click="openEditElementInTableModal(item, 'unitPriceTaxIncluded', 'Cena brutto (1szt)', 'INT', 'Zmiana ceny brutto (1szt)')">
                        <i class="mdi mdi-pencil"></i>
                      </button>
                    </div>
                  </template>

                  <template v-slot:status="{ item }">
                    <div class="ecat-row-width">
                      <p :class="orderHelper.getProductStatusColor(item.shopOrderProductStatus)">
                        {{ $t('core-orders.product.status.' + item.shopOrderProductStatus) }} </p>

                      <button class="btn btn-sm btn-success"
                              @click="openEditElementInTableModal(item, 'shopOrderProductStatus', 'Status', 'STATUS', 'Zmiana statusu')">
                        <i class="mdi mdi-pencil"></i>
                      </button>
                    </div>
                  </template>

                  <template v-slot:warehouse="{ item }">
                    <a :href="`/dashboard/admin/warehouse/show/${item.warehouseId}`" target="_blank">{{ item.warehouseName }}</a>
                  </template>

                  <template v-slot:warehouseEcat="{ item }">
                    Zarezerwowanych sztuk: {{ getProductVariationStockReservation(item.productVariationId) }}
                    <br />

                    <template v-if="getDepotSectionStocks(item.productVariationId).length > 0">
                      <div v-for="(depotSectionStock, index) in getDepotSectionStockDepots(item.productVariationId)" :key="index">
                        Hurtownia {{ depotSectionStock.depotName }} (suma: {{ getSumDepotSectionStocks(depotSectionStock.depotId, item.productVariationId) }}):
                        <br />
                        <ul v-for="(depotSection, index) in getDepotSectionStocks(item.productVariationId).filter(element => element.depotId === depotSectionStock.depotId)" :key="index">
                          <li class="mb-2">
                            {{ depotSection.depotSectionName }}: {{ depotSection.quantity }}
                          </li>
                        </ul>
                      </div>

                      <p>Łącznie:
                        <span :id="`sum_depot_stocks_${item.id}`" class="text-underline">{{ getSumDepotStocks(item.productVariationId) }}</span>
                        - <span :id="`stock_reservation_${item.id}`" class="text-underline">{{ getProductVariationStockReservation(item.productVariationId) }}</span>
                        = <span :id="`sum_${item.id}`" class="text-underline">{{ getSumDepotStocks(item.productVariationId) - getProductVariationStockReservation(item.productVariationId) }}</span>
                      </p>
                      <b-tooltip :target="`sum_depot_stocks_${item.id}`" placement="right">Ilość z Hurtowni ECAT</b-tooltip>
                      <b-tooltip :target="`stock_reservation_${item.id}`" placement="right">Zarezerwowanych sztuk</b-tooltip>
                      <b-tooltip :target="`sum_${item.id}`" placement="right">Suma sztuk</b-tooltip>
                    </template>
                    <template v-else>
                      Nie dotyczy
                    </template>
                  </template>

                  <template v-slot:trackingUrl="{ item }">
                    <template v-if="item.carrierOrderId">
                      <div :set="carrierOrder = getCarrierOrder(item.carrierOrderId)">
                        <template v-if="carrierOrder && carrierOrder.trackingUrl">
                          <div :set="carrierOperator = getCarrierOperator(carrierOrder.operator)">
                            <a v-if="carrierOperator" :href="carrierOrder.trackingUrl" target="_blank">{{ carrierOperator.name }}</a>
                          </div>
                        </template>
                      </div>
                    </template>
                  </template>

                  <template v-slot:trackingNumber="{ item }">
                    <div class="ecat-row-width">
                      <template v-if="item.carrierOrderId">
                        <div :set="carrierOrder = getCarrierOrder(item.carrierOrderId)">
                          <template v-if="carrierOrder && carrierOrder.trackingNumber">
                            <p>{{ carrierOrder.trackingNumber }}</p>

                            <div class="button-items">
                              <ecat-button :callback="() => removeCarrierOrder(carrierOrder)" variant="danger" size="sm">
                                <i class="mdi mdi-trash-can" />
                              </ecat-button>

                              <b-button @click="clipboardHelper.copy(carrierOrder.trackingNumber)" variant="primary" class="btn-sm">
                                <i class="mdi mdi-clipboard" />
                              </b-button>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>

                  <template v-slot:broadcast="{ item }">
                    <template v-if="item.carrierOrderId">
                      <div :set="carrierOrder = getCarrierOrder(item.carrierOrderId)">
                        <template v-if="carrierOrder && carrierOrder.orderId">
                          <a class="text-primary clickable-element mb-2" @click="getWaybillUrl(carrierOrder)">Pobierz
                            list przewozowy id: {{ carrierOrder.id }}</a>
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <p class="text-danger">Nie zlecono wysyłki</p>
                    </template>
                  </template>

                  <template v-slot:action="{ item }">
                    <div style="width: 180px;">
                      <div class="float-right">
                        <template v-if="getCarrierOrderNameToShipment(item)">
                          <p>{{ getCarrierOrderNameToShipment(item) }}</p>
                        </template>

                        <template
                            v-if="['PREPARED_TO_SHIP', 'SENT_TO_US_FROM_WHOLESALER', 'SENT_TO_CUSTOMER_FROM_OUR_WAREHOUSE', 'REALIZED_FROM_WAREHOUSE'].includes(item.shopOrderProductStatus)">
                          <template v-if="item.carrierOrderId || shopOrder.carrierId">
                            <a :href="getPathNameToSendPackage(getCarrierOrder(item.carrierOrderId), item.carrierOrderId)"
                               class="btn btn-warning btn-sm mb-2">Zleć wysyłkę:
                              {{ item.carrierOrderId ? getOperatorName(item) : getOrderCarrierName() }}</a>
                          </template>
                        </template>
                      </div>

                      <router-link
                          :to="{ path: '/dashboard/core/order-add-carrier', query: { orderId: shopOrder ? shopOrder.id : '' }}"
                          class="btn btn-success btn-sm mb-2">Dodaj ręcznie przewoźnika
                      </router-link>

                      <div class="float-right">
                        <b-button v-if="item.carrierOrderId" class="btn-sm mb-2" variant="secondary"
                                  @click="openAddTrackingModal(item.carrierOrderId)">Dodaj tracking
                        </b-button>
                      </div>

                      <div class="float-right">
                        <template v-if="item.carrierOrderId">
                          <div :set="carrierOrder = getCarrierOrder(item.carrierOrderId)">
                            <template v-if="carrierOrder && !carrierOrder.waybillPdf">
                              <input id="send-waybills" accept="pdf" hidden
                                     type="file" @change="event => sendWaybills(item.carrierOrderId, event)"/>
                              <label class="btn btn-sm mb-2 btn-primary" for="send-waybills">Wgraj list
                                przewozowy</label>
                            </template>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </ecat-table>

              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h3>Komentarze do zamówienia</h3>

              <div>
                <a class="clickable-element btn btn-success mb-2 mx-2" @click="modals.comment.visibleModal = true">
                  <i class="mdi mdi-plus mr-2"></i> Dodaj</a>
              </div>

              <ecat-table
                  ref="commentsTable"
                  :fields="commentsFields()"
                  :items="loadComments"
                  :pagination-top="true"
                  :table="commentsTable"
              >
                <template v-slot:author="{ item }">
                  <div :set="author = getCommentAuthor(item.userId, false)">
                    <span>{{
                        author ? `${author.firstName} ${author.lastName} (${author.email})` : 'Ładowanie...'
                      }}</span>
                  </div>
                </template>

                <template v-slot:action="{ item }">
                  <a class="clickable-element text-danger" @click="deleteComment(item)"><i
                      class="mdi mdi-trash-can font-size-18"/></a>
                </template>
              </ecat-table>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-3">
          <div class="card">
            <div class="card-body">
              <h4>Komentarze dla logistyki</h4>
              <hr>

              <ecat-table
                  ref="commentsForLogistics"
                  :fields="commentsForLogisticsFields()"
                  :items="loadCommentsForLogistics"
                  :pagination-top="true"
                  :table="commentsForLogisticsTable"
              >
                <template v-slot:author="{ item }">
                  <div :set="author = getCommentAuthor(item.userId, false)">
                    <span>{{
                        author ? `${author.firstName} ${author.lastName} (${author.email})` : 'Ładowanie...'
                      }}</span>
                  </div>
                </template>
              </ecat-table>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-3">
          <div class="card">
            <div class="card-body">
              <h4>Uwagi do zamówienia</h4>
              <hr>
              <template v-if="shopOrder.note">
                <p>{{ shopOrder.note }}</p>
              </template>
              <template v-else>
                <p>Brak</p>
              </template>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <table class="table invoice-total" style="background-color: var(--body-bg)">

                <tbody>
                <tr>
                  <td><strong>Ilość produktów:</strong></td>
                  <td v-if="productsTable.items">{{ productsQuantitySum() }}</td>
                  <td v-else>Ładowanie...</td>
                </tr>
                <tr>
                  <td><strong>Wartość produktów:</strong></td>
                  <td v-if="productsTable.items">{{ productsPriceSum() }}</td>
                  <td v-else>Ładowanie...</td>
                </tr>
                <tr>
                  <td><strong>Wartość przesyłki:</strong></td>
                  <td>{{ priceHelper.format(shopOrder.shippingCostTaxIncluded, market.currency) }}
<!--                    <button class="btn btn-success btn-sm" @click="editParcelValue"><i class="mdi mdi-pencil"></i>-->
<!--                    </button>-->
                  </td>
                </tr>
                <tr>
                  <td><strong>Realna wartość przesyłki:</strong></td>
                  <td> {{ priceHelper.format(shopOrder.realShippingCostTaxIncluded, market.currency) }}
                    <button class="btn btn-success btn-sm" @click="editRealParcelValue"><i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td><strong>Wartość zamówienia:</strong></td>
                  <td>{{
                      priceHelper.format(Number(shopOrder.shippingCostTaxIncluded) + Number(shopOrder.totalProductsCostTaxIncluded), market.currency)
                    }}
                  </td>
                </tr>
                <tr>
                  <td><strong>Zysk ECAT:</strong></td>
                  <td>{{ priceHelper.format(shopOrder.ecatProfit, market.currency) }}</td>
                </tr>
                <tr>
                  <td><strong>Zysk Sklep:</strong></td>
                  <td>{{ priceHelper.format(shopOrder.operatorProfit, market.currency) }}</td>
<!--                  <td>{{ priceHelper.format(shopOrder.operatorProfit, market.currency) }} (korekta transportu: {{ priceHelper.format(shippingCostDifference(), market.currency) }})</td>-->
                </tr>
                <tr>
                  <td><strong>Zysk:</strong></td>
                  <td>{{ priceHelper.format(shopOrder.ecatProfit + shopOrder.operatorProfit, market.currency) }}</td>
                </tr>
                <tr>
                  <td><strong>Prowizja allegro:</strong></td>
                  <td>{{ priceHelper.format(shopOrder.allegroProvision, market.currency) }}</td>
                </tr>
                <tr v-if="shopOrder.allegroAccountType === 'EXTERNAL' && tpayAmount">
                  <td><strong>Kwota opłaty zamówienia przez operatora:</strong></td>
                  <td>{{ priceHelper.format(tpayAmount.paidAmount, market.currency) }}</td>
                </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>

      <delivery-address-table ref="deliveryAddressTable" v-if="shopOrder" :shop-order-id="shopOrder.id"/>
      <order-logs-table ref="ordersLogsTable" v-if="shopOrder" :shop-order-id="shopOrder.id"/>
      <order-fees-logs-table v-if="shopOrder && shopOrder.allegroAccountType === 'EXTERNAL' && market" :shop-order-id="shopOrder.id" :market="market" />

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4>Kontakt z obsługą zamówienia:</h4>
              <textarea id="contact-with-order-processing-description"
                        v-model="form.contactWithOrderProcessing.description" class="form-control mb-2"
                        placeholder="Treść" style="min-height: 40px;" type="text"/>
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <b-button class="ml-1 w-sm" variant="primary">Wyślij</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </template>
    <template v-else>
      <div class="text-center py-3">
        <h5>{{ $t('message.loading') }}</h5>
        <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
      </div>
    </template>

    <b-modal
        v-if="editingShopOrder"
        id="modal-1"
        v-model="modals.priority.visibleModal"
        hide-footer
        title="Zmiana priorytetu zamówienia"
        title-class="font-18"
        @esc="modals.priority.visibleModal = false"
        @hide="modals.priority.visibleModal = false">

      <div class="form-group">
        <label>Priorytet</label>
        <multiselect v-model="editingShopOrder.priority"
                     :custom-label="value => priorityText(Number(value))"
                     :options="selectors.priority.options"
                     placeholder="Wybierz priorytet">
        </multiselect>
      </div>

      <div class="text-center">
        <b-button variant="success" @click="editOrder(modals.priority, 'Pomyślnie zmieniono priorytet')">Zmień
          priorytet
        </b-button>
        <b-button class="ml-1" variant="danger" @click="modals.priority.visibleModal = false">{{
            $t('message.cancel')
          }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="editingShopOrder"
        id="modal-2"
        v-model="modals.carrier.visibleModal"
        hide-footer
        title="Zmiana sposobu wysyłki"
        title-class="font-18"
        @esc="modals.carrier.visibleModal = false"
        @hide="modals.carrier.visibleModal = false">

      <div class="form-group">
        <label>Sposób wysyłki</label>
        <multiselect
            v-model="editingShopOrder.carrierId"
            :custom-label="value => getCarrier(value).name"
            :options="carriersIds ? carriersIds : []"
            placeholder="Wybierz sposób wysyłki"
            :show-labels="false"
        />
      </div>

      <template v-if="editingShopOrder.carrierId">
        <div :set="selectedCarrier = getCarrier(editingShopOrder.carrierId)" class="form-group">
          <template v-if="selectedCarrier && selectedCarrier.name.toLowerCase().includes('paczkomat')">
            <label>Kod paczkomatu</label>
            <input v-model="editingShopOrder.parcelLockerCode" class="form-control" type="text"/>
          </template>
        </div>
      </template>

      <div class="text-center">
        <b-button variant="success" @click="editOrderCarrier">Zmień
          sposób wysyłki
        </b-button>
        <b-button class="ml-1" variant="danger" @click="modals.carrier.visibleModal = false">{{
            $t('message.cancel')
          }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="editingShopOrder"
        id="modal-2"
        v-model="modals.shopOrderStatus.visibleModal"
        hide-footer
        title="Zmiana statusu zamówienia"
        title-class="font-18"
        @esc="modals.shopOrderStatus.visibleModal = false"
        @hide="modals.shopOrderStatus.visibleModal = false">

      <div class="form-group">
        <label>Status zamówienia</label>
        <multiselect
            v-model="editingShopOrder.status"
            :custom-label="value => $t('core-orders.order.status.' + value)"
            :options="selectors.shopOrderStatus.options"
            placeholder="Wybierz status zamówienia"
            :show-labels="false"
        />
      </div>

      <div class="text-center">
        <b-button variant="success" @click="editOrder(modals.shopOrderStatus, 'Pomyślnie zmieniono status zamówienia')">
          Zmień status zamówienia
        </b-button>
        <b-button class="ml-1" variant="danger" @click="modals.shopOrderStatus.visibleModal = false">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-3"
        v-model="modals.comment.visibleModal"
        hide-footer
        title="Tworzenie komentarza"
        title-class="font-18"
        @esc="hideCommentModal"
        @hide="hideCommentModal">

      <div class="form-group">
        <label>Treść</label>
        <textarea id="description" v-model="form.comment.description"
                  :class="{ 'is-invalid': $v.form.comment.description.$error }" class="form-control"
                  type="text"/>
        <div v-if="!$v.form.comment.description.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox mb-3">
          <input id="for-logistics" v-model="form.comment.forLogistics" class="custom-control-input" type="checkbox"/>
          <label class="custom-control-label" for="for-logistics">Dla logistyki</label>
        </div>
      </div>

      <div class="text-center">
        <ecat-button :callback="() => createComment()" variant="success" message="Utwórz komentarz" />
        <b-button class="ml-1" variant="danger" @click="hideCommentModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="editingShopOrder"
        id="modal-4"
        v-model="modals.problemWithRealization.visibleModal"
        hide-footer
        title="Zgłaszanie problemu z realizacją"
        title-class="font-18"
        @esc="hideProblemWithRealizationModal"
        @hide="hideProblemWithRealizationModal">

      <div class="form-group">
        <label>Treść</label>
        <textarea id="description" v-model="editingShopOrder.problem" class="form-control" type="text"/>
      </div>

      <div class="text-center">
        <b-button variant="success"
                  @click="editOrder(modals.problemWithRealization, 'Pomyślnie zgłoszono problem z realizacją')">
          Zgłoś problem z realizacją
        </b-button>
        <b-button class="ml-1" variant="danger" @click="hideProblemWithRealizationModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="editingShopOrder"
        id="modal-4"
        v-model="modals.parcelValue.visibleModal"
        hide-footer
        title="Zmiana wartości przesyłki"
        title-class="font-18"
        @esc="modals.parcelValue.visibleModal = false"
        @hide="modals.parcelValue.visibleModal = false">

      <div class="form-group">
        <label>Wartość przesyłki</label>
        <input id="parcel-value" v-model.number="editingShopOrder.shippingCostTaxIncluded" class="form-control"
               type="text"/>
      </div>

      <div class="text-center">
        <b-button variant="success" @click="editOrder(modals.parcelValue, 'Pomyślnie zmieniono wartość przesyłki')">
          Zmień wartość przesyłki
        </b-button>
        <b-button class="ml-1" variant="danger" @click="modals.parcelValue.visibleModal = false">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="editingShopOrder"
        id="modal-5"
        v-model="modals.realParcelValue.visibleModal"
        hide-footer
        title="Zmiana realnej wartości przesyłki"
        title-class="font-18"
        @esc="modals.realParcelValue.visibleModal = false"
        @hide="modals.realParcelValue.visibleModal = false">

      <div class="form-group">
        <label>Realna wartość przesyłki</label>
        <input
            id="real-parcel-value"
            v-model="editingShopOrder.realShippingCostTaxIncluded"
            class="form-control"
            type="number"
            step="any"
        />
      </div>

      <div class="text-center">
        <b-button variant="success" @click="editOrder(modals.realParcelValue, 'Pomyślnie zmieniono realną wartość przesyłki')">
          Zmień realną wartość przesyłki
        </b-button>
        <b-button class="ml-1" variant="danger" @click="modals.realParcelValue.visibleModal = false">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-6"
        v-model="modals.massChangeProductStatus.visibleModal"
        hide-footer
        title="Zmiana statusów produktów"
        title-class="font-18"
        @esc="modals.massChangeProductStatus.visibleModal = false"
        @hide="modals.massChangeProductStatus.visibleModal = false">

      <div class="form-group">
        <label>Produkty</label>
        <template v-if="productsTable.items">
          <div v-for="(orderProduct, index) in productsTable.items" :key="index"
               class="custom-control custom-checkbox">
            <input :id="orderProduct.id" v-model="form.massChangeProductStatus.productIds" :value="orderProduct.id"
                   class="custom-control-input" type="checkbox"/>
            <label :for="orderProduct.id" class="custom-control-label">{{ orderProduct.name }}</label>
          </div>
        </template>
      </div>

      <div class="form-group">
        <label>Status</label>
        <multiselect v-model="form.massChangeProductStatus.status"
                     :class="{ 'is-invalid': $v.form.massChangeProductStatus.status.$error }"
                     :custom-label="value => $t('core-orders.product.status.' + value)"
                     :options="shopOrderProductStatuses ? shopOrderProductStatuses : []"
                     placeholder="Wybierz status produktu">
        </multiselect>
        <div v-if="!$v.form.massChangeProductStatus.status.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="text-center">
        <b-button variant="success" @click="massProductChangeStatus">
          Zapisz
        </b-button>
        <b-button class="ml-1" variant="danger" @click="modals.massChangeProductStatus.visibleModal = false">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="invoiceAddress"
        id="modal-7"
        v-model="modals.invoiceVat.visibleModal"
        :scrollable="true"
        hide-footer
        title="Fakturowanie zamówienia"
        title-class="font-18"
        @esc="hideInvoiceModal"
        @hide="hideInvoiceModal">

      <div class="form-group">
        <label for="firstName">Imię</label>
        <input id="firstName" v-model="invoiceAddress.firstName"
               :class="{ 'is-invalid': $v.invoiceAddress.firstName.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.invoiceAddress.firstName.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label for="lastName">Nazwisko</label>
        <input id="lastName" v-model="invoiceAddress.lastName"
               :class="{ 'is-invalid': $v.invoiceAddress.lastName.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.invoiceAddress.lastName.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label for="city">Miasto</label>
        <input id="city" v-model="invoiceAddress.city" :class="{ 'is-invalid': $v.invoiceAddress.city.$error }"
               class="form-control"
               type="text"/>
        <div v-if="!$v.invoiceAddress.city.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label for="street">Ulica</label>
        <input id="street" v-model="invoiceAddress.street" :class="{ 'is-invalid': $v.invoiceAddress.street.$error }"
               class="form-control"
               type="text"/>
        <div v-if="!$v.invoiceAddress.street.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label for="post-code">Kod pocztowy</label>
        <input id="post-code" v-model="invoiceAddress.postCode"
               :class="{ 'is-invalid': $v.invoiceAddress.postCode.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.invoiceAddress.postCode.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label for="country">Kraj</label>
        <input id="country" v-model="invoiceAddress.country" :class="{ 'is-invalid': $v.invoiceAddress.country.$error }"
               class="form-control"
               type="text"/>
        <div v-if="!$v.invoiceAddress.country.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label for="company-name">Nazwa firmy</label>
        <input id="company-name" v-model="invoiceAddress.companyName" class="form-control" type="text"/>
      </div>

      <div class="form-group">
        <label for="company-nin">NIP Firmy</label>
        <input id="company-nin" v-model="invoiceAddress.companyNin" class="form-control" type="text"/>
      </div>

      <div class="form-group">
        <label for="phone-number">Telefon</label>
        <input id="phone-number" v-model="invoiceAddress.phoneNumber" class="form-control" type="text"/>
      </div>

      <div class="text-center">
        <template v-if="modals.changeAddress">
          <b-button class="mx-2" variant="success" @click="updateInvoiceAddress(false, '')">
            Zaktualizuj adres
          </b-button>
        </template>
        <template v-else>
          <b-button variant="success" @click="updateInvoiceAddress(true, 'VAT', true)">
            Zatwierdź wpłatę i wystaw fakturę
          </b-button>

          <b-button class="mx-2" variant="success" @click="updateInvoiceAddress(true, 'VAT')">
            Wystaw fakturę
          </b-button>
        </template>

        <b-button class="my-3" variant="danger" @click="hideInvoiceModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-7"
        size="xl"
        v-model="modals.addProduct.visibleModal"
        :scrollable="true"
        :title="modals.addProduct.isPrivate ? 'Dodawanie produktu stacjonarnego' : 'Dodawanie produktu'"
        hide-footer
        title-class="font-18"
        @esc="hideAddProductModal"
        @hide="hideAddProductModal">

      <div class="form-group">
        <label for="sku">SKU</label>
        <input id="sku" v-model="form.addProduct.sku" :class="{ 'is-invalid': $v.form.addProduct.sku.$error }"
               class="form-control" type="text"
               @input="loadVariations"/>
        <div v-if="!$v.form.addProduct.sku.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label for="number">Ilość</label>
        <input id="number" v-model.number="form.addProduct.amount"
               :class="{ 'is-invalid': $v.form.addProduct.amount.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.form.addProduct.amount.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="table-responsive">
        <label :class="{ 'is-invalid': $v.form.addProduct.productVariationId.$error }">Wariacje</label>
        <b-table
            ref="productVariationsTable"
            :busy.sync="loadingVariations"
            :current-page="productVariationsTable.currentPage"
            :empty-filtered-text="$t('message.no-records')"
            :empty-text="$t('message.no-elements')"
            :fields="variationsFields()"
            :filter="productVariationsTable.filter"
            :filter-included-fields="productVariationsTable.filterOn"
            :items="productVariationsTable.elements"
            :per-page="productVariationsTable.perPage"
            :show-empty="true"
            :sort-by.sync="productVariationsTable.sortBy"
            :sort-desc.sync="productVariationsTable.sortDesc"
            responsive="sm" @filtered="elements => paginationHelper.onFiltered(productVariationsTable, elements)">
          <div slot="table-busy" class="text-center">
            <h5>{{ $t('message.loading') }}</h5>
            <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
          </div>

          <template v-slot:cell(element)="{ item }">
            <input :id="item.id" v-model="form.addProduct.productVariationId" :name="item.id" :value="item.id"
                   type="radio">
          </template>

          <template v-slot:cell(availableIn)="{ item }">
            <span>{{ getAvailableIn(item.id) ? getAvailableIn(item.id).availableIn : '0' }}</span>
          </template>

          <template v-slot:cell(ean)="{ item }">
            <template v-if="item.ean">
              <div :set="ean = jsonUtil.asArray(item.ean)">
                <template v-if="ean.length > 0">
                  <span>{{ ean[0] }}</span>
                  <span v-if="ean.length > 1" class="badge badge-soft-success font-size-11">+{{
                      ean.length - 1
                    }} {{ $t('message.others') }}</span>
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </div>
            </template>
          </template>

        </b-table>
        <div v-if="!$v.form.addProduct.productVariationId.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <template v-if="loadedProductWithVariation">
        <p>Produkt: <a :href="`/dashboard/admin/product/show/${loadedProductWithVariation.id}`">{{ loadedProductWithVariation.name }}</a></p>
      </template>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="addProduct">
          Dodaj produkt
        </b-button>

        <b-button class="my-3" variant="danger" @click="hideAddProductModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-8"
        v-model="modals.massWarehouseInfo.visibleModal"
        :scrollable="true"
        hide-footer
        title="Dodawanie dodatkowych informacji o zamówionych produktach z hurtowni"
        title-class="font-18"
        @esc="hideMassWarehouseModal"
        @hide="hideMassWarehouseModal">

      <div class="form-group">
        <label>Produkty</label>
        <template v-if="productsTable.items">
          <div v-for="(orderProduct, index) in productsTable.items" :key="index"
               class="custom-control custom-checkbox">
            <input :id="orderProduct.id" v-model="form.massWarehouseInfo.productsIds" :value="orderProduct.id"
                   class="custom-control-input" type="checkbox"/>
            <label :for="orderProduct.id" class="custom-control-label">{{ orderProduct.name }}</label>
          </div>
        </template>
      </div>

      <div class="form-group">
        <label for="nr-fv-warehouse">Nr FV Hurtowni</label>
        <input id="nr-fv-warehouse" v-model="form.massWarehouseInfo.warehouseInvoiceId"
               :class="{ 'is-invalid': $v.form.massWarehouseInfo.warehouseInvoiceId.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.form.massWarehouseInfo.warehouseInvoiceId.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label for="nr-order-warehouse">Nr Zamówienia Hurtowni</label>
        <input id="nr-order-warehouse" v-model="form.massWarehouseInfo.warehouseOrderId"
               :class="{ 'is-invalid': $v.form.massWarehouseInfo.warehouseOrderId.$error }"
               class="form-control" type="text"/>
        <div v-if="!$v.form.massWarehouseInfo.warehouseOrderId.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label for="warehouse-price">Wartość zamówienia</label>
        <input id="warehouse-price" v-model="form.massWarehouseInfo.warehousePrice"
               :class="{ 'is-invalid': $v.form.massWarehouseInfo.warehousePrice.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.form.massWarehouseInfo.warehousePrice.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="addMassWarehouseInfo">
          Zapisz
        </b-button>

        <b-button class="my-3" variant="danger" @click="hideMassWarehouseModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-9"
        v-model="modals.manualCorrection.visibleModal"
        :scrollable="true"
        hide-footer
        title="Dodaj korektę do zamówienia"
        title-class="font-18"
        @esc="hideManualCorrectionModal"
        @hide="hideManualCorrectionModal">

      <div class="form-group">
        <label for="preview-url">Link do korekty</label>
        <input id="preview-url" v-model="form.manualCorrection.previewUrl"
               :class="{ 'is-invalid': $v.form.manualCorrection.previewUrl.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.form.manualCorrection.previewUrl.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label for="price">Kwota</label>
        <input id="price" v-model.number="form.manualCorrection.price"
               :class="{ 'is-invalid': $v.form.manualCorrection.price.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.form.manualCorrection.price.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label for="service-id">Numer</label>
        <input id="service-id" v-model="form.manualCorrection.serviceId"
               :class="{ 'is-invalid': $v.form.manualCorrection.serviceId.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.form.manualCorrection.serviceId.required" class="invalid-feedback">{{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label for="hash">Hash</label>
        <input id="hash" v-model="form.manualCorrection.hash"
               :class="{ 'is-invalid': $v.form.manualCorrection.hash.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.form.manualCorrection.hash.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="createInvoiceCorrectionManually">
          Zapisz
        </b-button>

        <b-button class="my-3" variant="danger" @click="hideManualCorrectionModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-10"
        v-model="modals.editElementInTable.visibleModal"
        :title="`${modals.editElementInTable.title}`"
        hide-footer
        title-class="font-18"
        @esc="hideEditElementInTableModal"
        @hide="hideEditElementInTableModal">

      <label>{{ modals.editElementInTable.elementName }}</label>
      <template v-if="modals.editElementInTable.type === 'INT'">
        <input
            v-model="modals.editElementInTable.item[modals.editElementInTable.element]"
            class="form-control"
            type="number"
        />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'STRING'">
        <input v-model="modals.editElementInTable.item[modals.editElementInTable.element]" class="form-control"
               type="text"/>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'STATUS'">
        <vue-multiselect
            v-model="modals.editElementInTable.item[modals.editElementInTable.element]"
            :options="shopOrderProductStatuses ? shopOrderProductStatuses : []"
            placeholder="Wybierz status produktu"
            :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <span :class="orderHelper.getProductStatusColor(option)">
              {{ $t('core-orders.product.status.' + option) }}
            </span>
          </template>

          <template slot="option" slot-scope="{ option }">
            <span :class="orderHelper.getProductStatusColor(option)">
                 {{ $t('core-orders.product.status.' + option) }}
            </span>
          </template>
        </vue-multiselect>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'TAX'">
        <multiselect
            :custom-label="value => getTaxRate(value) + '%'"
            :options="taxesIds ? taxesIds : []"
            :value="modals.editElementInTable.item.taxId"
            @select="value => modals.editElementInTable.item.taxId = value"
            :show-labels="false"
        />
      </template>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="editShopOrderProduct">
          Zapisz
        </b-button>

        <b-button class="my-3" variant="danger" @click="hideEditElementInTableModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-11"
        v-model="modals.addTracking.visibleModal"
        hide-footer
        title="Dodaj numer śledzenia dla przesyłki:"
        title-class="font-18"
        @esc="modals.addTracking.visibleModal = false"
        @hide="modals.addTracking.visibleModal = false">

      <div class="form-group">
        <label>Numer śledzenia</label>
        <input v-model="modals.addTracking.form.trackingNumber"
               :class="{ 'is-invalid': $v.modals.addTracking.form.trackingNumber.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.modals.addTracking.form.trackingNumber.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Link śledzenia</label>
        <input v-model="modals.addTracking.form.trackingUrl"
               :class="{ 'is-invalid': $v.modals.addTracking.form.trackingUrl.$error }" class="form-control"
               type="text"/>
        <div v-if="!$v.modals.addTracking.form.trackingUrl.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="addTracking">
          Zapisz
        </b-button>

        <b-button class="my-3" variant="danger" @click="modals.addTracking.visibleModal = false">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="editingShopOrder"
        id="modal-12"
        v-model="modals.operator.visibleModal"
        hide-footer
        title="Zmiana przypisanego operatora"
        title-class="font-18"
        @esc="modals.operator.visibleModal = false"
        @hide="modals.operator.visibleModal = false">

      <p>TODO</p>

      <div class="text-center">
        <b-button variant="success" @click="editOrder(modals.operator, 'Pomyślnie zmieniono operatora')">Zmień
          operatora
        </b-button>
        <b-button class="ml-1" variant="danger" @click="modals.operator.visibleModal = false">{{
            $t('message.cancel')
          }}
        </b-button>
      </div>
    </b-modal>


  </Layout>
</template>

<style scoped>
.panel-primary {
  border-color: #0f9cd8 !important;
}

.panel {
  margin-bottom: 20px;
  background-color: var(--card-bg);
  border: 1px solid transparent;
  border-radius: 4px;
}

.panel-primary > .panel-heading {
  background-color: #0f9cd8;
  border-color: #0f9cd8;
}

.panel-primary > .panel-heading {
  color: var(--card-bg);
  background-color: #337ab7;
  border-color: #337ab7;
}

.panel-body {
  padding: 15px;
}

.panel-heading {
  color: var(--card-bg);
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.full-height {
  height: 100%;
}


.order-table {
  border-collapse: collapse !important;
  display: table !important;
  //width: 100%;
}

.order-table th, .order-table td {
  //padding-left: 0 !important;
  padding: 0.75rem !important;
  //padding: 0 !important;
}
</style>