<template>
  <b-modal
      v-model="visibleModal"
      hide-footer
      :title="`${title}`"
      title-class="font-18"
      @esc="hideModal"
      @hide="hideModal">

    <label v-if="elementName">{{ elementName }}</label>
    <template v-if="type === 'INT'">
      <input v-model.number="item[element]" type="number" class="form-control" />
    </template>
    <template v-else-if="type === 'STRING'">
      <input v-model="item[element]" type="text" class="form-control" />
    </template>

    <div class="text-center">
      <b-button class="mx-2" variant="success" @click="editAddress">
        Zapisz
      </b-button>

      <b-button variant="danger" class="my-3" @click="hideModal">
        {{ $t('message.cancel') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";

export default {
  data() {
    return {
      item: null,
      title: "",
      visibleModal: false,
      element: "",
      elementName: "",
      type: "",
      callback: null
    }
  },
  
  methods: {
    hideModal() {
      this.visibleModal = false
      this.item = null
      this.title = ""
      this.element = ""
      this.elementName = ""
      this.type = ""
    },

    openModal(item, element, elementName, type, title, callback = null) {
      this.item = Object.assign({}, item)
      this.element = element
      this.title = title
      this.elementName = elementName
      this.type = type
      this.visibleModal = true
      this.callback = callback
    },

    editAddress() {
      const json = JSON.stringify(this.item);
      axios.post(`/address`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(({ data }) => {
        this.item = data
        this.$emit("update-address", this.item)
        this.hideModal()
        Swal.fire("Sukces!", "Zaktualizowano informacje o adresie", "success").then(() => {
          if (this.callback) {
            this.callback()
          }
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    }
  }
  
}
</script>