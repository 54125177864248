<template>
  <div>
    <edit-address-element-modal ref="editAddressElementModal" @update-address="updateAddress"/>

    <div class="card">
      <div class="card-body">
        <h4>Adres dostawy</h4>

        <ecat-table
            ref="deliveryAddressTable"
            class="delivery-address-table"
            :table="table"
            :fields="getFields()"
            :items="loadDeliveryAddress"
            :options="false">
          <template v-slot:firstName="{ item }">
            <span @click="openEditElementModal(item, 'firstName', 'Imię', 'STRING')">{{productsHelper.parseText(item.firstName)}}</span>
          </template>

          <template v-slot:lastName="{ item }">
            <span @click="openEditElementModal(item, 'lastName', 'Nazwisko', 'STRING')">{{ productsHelper.parseText(item.lastName) }}</span>
          </template>

          <template v-slot:companyName="{ item }">
            <span @click="openEditElementModal(item, 'companyName', 'Nazwa firmy', 'STRING')">{{ productsHelper.parseText(item.companyName) }}</span>
          </template>

          <template v-slot:companyNin="{ item }">
            <span @click="openEditElementModal(item, 'companyNin', 'Identyfikator podatkowy', 'STRING')">{{ productsHelper.parseText(item.companyNin) }}</span>
          </template>

          <template v-slot:country="{ item }">
            <span @click="openEditElementModal(item, 'country', 'Kraj', 'STRING')">{{ productsHelper.parseText(item.country) }}</span>
          </template>

          <template v-slot:city="{ item }">
            <span @click="openEditElementModal(item, 'city', 'Miasto', 'STRING')">{{ productsHelper.parseText(item.city) }}</span>
          </template>

          <template v-slot:postCode="{ item }">
            <span @click="openEditElementModal(item, 'postCode', 'Kod pocztowy', 'STRING')">{{ productsHelper.parseText(item.postCode) }}</span>
          </template>

          <template v-slot:street="{ item }">
            <span @click="openEditElementModal(item, 'street', 'Ulica i numer lokalu', 'STRING')">{{ productsHelper.parseText(item.street) }}</span>
          </template>

          <template v-slot:phoneNumber="{ item }">
            <span @click="openEditElementModal(item, 'phoneNumber', 'Numer telefonu', 'STRING')">{{ productsHelper.parseText(item.phoneNumber) }}</span>
          </template>
        </ecat-table>

        <p class="text-danger">Aby móc edytować dane, należy kliknąć wybrane pole.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";

import editAddressElementModal from "./edit-address-element-modal.vue";
import {productsHelper} from "@/helpers/products-helper";

export default {
  name: "delivery-address-table",
  components: {

    editAddressElementModal
  },

  props: {
    shopOrderId: {
      type: String,
      required: true
    }
  },

  computed: {
    productsHelper() {
      return productsHelper
    }
  },

  data() {
    return {
      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 1,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: [],
        isBusy: false
      },
    }
  },

  methods: {
    deliveryAddress() {
      return this.table.items[0]
    },

    getFields() {
      return [
        { key: "firstName", slot: true, label: "Imię" },
        { key: "lastName", slot: true, label: "Nazwisko" },
        { key: "companyName", slot: true, label: "Nazwa firmy" },
        { key: "companyNin", slot: true, label: "Identyfikator podatkowy" },
        { key: "country", slot: true, label: "Kraj" },
        { key: "city", slot: true, label: "Miasto" },
        { key: "postCode", slot: true, label: "Kod pocztowy" },
        { key: "street", slot: true, label: "Ulica i numer lokalu" },
        { key: "phoneNumber", slot: true, label: "Numer telefonu" }
      ]
    },

    async loadDeliveryAddress() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/shop-order/delivery-address/${this.shopOrderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage
          }
        });

        this.table.items = [data]
        this.table.totalRows = 1;
        this.table.rows = 1;
      } catch (error) {
        this.table.items = []
        errorCatcher.catchErrors(error)
      }

      return this.table.items
    },

    updateAddress() {
      this.$refs.deliveryAddressTable.refresh()
    },

    openEditElementModal(item, element, elementName, type, title = "Edycja adresu dostawy") {
      this.$refs.editAddressElementModal.openModal(item, element, elementName, type, title)
    }
  }

}
</script>

<style>
.delivery-address-table span {
  position: relative;
  cursor: pointer;
}

.delivery-address-table span::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  border-bottom: 1px dotted #ffffff;
}
</style>